import React from 'react';
import Creator  from  './Creator';

class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            auth   : false,
            pw: "",

            cb : props.cb
        }
        this.handlePwChange = this.handlePwChange.bind(this)
        this.handleLogin = this.handleLogin.bind(this);
    }

    handleLogin() {
        const PW_hard = '2020';
        const {pw, cb} = this.state;
        if (pw == PW_hard) {
            cb();
        }
    }
    handlePwChange(event) {
        event.preventDefault();
        const {id , value} = event.target   
        
        this.setState(prevState => ({
            ...prevState,
            [id] : value
        }))
    }
    render() {
        return (
           <div>
               <label>Password Pls:</label>
               <input type='password' id='pw' onChange={this.handlePwChange}></input>
               <button onClick={this.handleLogin}> Login</button>
           </div>
        )
    }
}

export default Login
