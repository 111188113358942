import React from "react";
import "./misc.css";

class Creator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
    this.handleGenerate = this.handleGenerate.bind(this);
  }

  parseListItem(list, isVoordeel) {
    var res = "";
    var item_array = list.split("\n");
    var item;
    for (item of item_array) {
      if (item.length > 2) {
        if (isVoordeel) {
          res += `✅ ${item} <br/>`;
        } else {
          res += `<li>${item}</li>`;
        }
      }
    }
    console.log(res);
    return res;
  }

  handleGenerate = (event) => {
    var desc = {};
    const formData = new FormData(event.target);
    event.preventDefault();
    for (let [key, value] of formData.entries()) {
      desc[key] = value;
      console.log(key + " " + desc[key]);
    }
    desc["voordelen"] = this.parseListItem(desc["voordelen"], true);
    desc["specifications"] = this.parseListItem(desc["specifications"], false);
    desc["package"] = this.parseListItem(desc["package"], false);
    const test = ` 
        
<div data-mce-fragment="1">
  <div data-mce-fragment="1">
    <span data-mce-fragment="1"
      >✔️ 7 dagen per week klantenservice<br data-mce-fragment="1" />✔️ Alle
      betalingen zijn 100% beveiligd<br data-mce-fragment="1" />✔️ Alleen
      vandaag gratis verzending<br data-mce-fragment="1" />✔️ 14 dagen retour
      garantie<br data-mce-fragment="1" />✔️ Niet goed? Geld terug<br
        data-mce-fragment="1" /><br data-mce-fragment="1"
    /></span>
  </div>
  <div style="text-align: center" data-mce-fragment="1">
    <span style="color: #c49e00">
      <strong data-mce-fragment="1">!LET OP!&nbsp;</strong
      ><strong data-mce-fragment="1"><br data-mce-fragment="1" /></strong></span
    >Momenteel nog&nbsp;maar&nbsp;<strong data-mce-fragment="1">11</strong
    ><strong data-mce-fragment="1">&nbsp;</strong>beschikbaar!<br
      data-mce-fragment="1"
    /><br data-mce-fragment="1" />
  </div>
  <div style="text-align: center" data-mce-fragment="1">
    <span style="color: #c49e00" data-mce-fragment="1">
      <strong data-mce-fragment="1"
        >LAATSTE ACTIEDAG 50% KORTING:&nbsp;</strong
      ></span
    >de actie wordt&nbsp;automatisch verwerkt.&nbsp;
  </div>
  <p style="text-align: left" data-mce-fragment="1">
    <strong data-mce-fragment="1"><br data-mce-fragment="1" />${desc["intro"]}</strong>
  </p>
</div>
<div data-mce-fragment="1">
  <img
    style="display: block; margin-left: auto; margin-right: auto"
    src="https://cdn.shopify.com/s/files/1/0282/3906/9270/files/1_480x480.gif?v=1608741752"
    alt=""
    width="396"
    height="396"
    data-mce-fragment="1"
  />
</div>

<p data-mce-fragment="1">${desc["main-text"]}</p>

<p data-mce-fragment="1">
  <img
    style="display: block; margin-left: auto; margin-right: auto"
    src="https://images-na.ssl-images-amazon.com/images/I/71%2BTXb0TazL._AC_SL1500_.jpg"
    alt=""
    width="398"
    height="336"
    data-mce-fragment="1"
  />
</p>
<p data-mce-fragment="1"><strong data-mce-fragment="1">Voordelen</strong></p>
<div>

<ul>${desc["voordelen"]}</ul>
</div>
<p data-mce-fragment="1">
  <img
    style="display: block; margin-left: auto; margin-right: auto"
    src="https://cdn.shopify.com/s/files/1/1463/8774/files/1_0bd73395-0d60-4362-82ac-40b1b39c3a05_large.gif?v=1560267928"
    alt=""
    width="394"
    height="395"
    data-mce-fragment="1"
  />
</p>
<p data-mce-fragment="1">${desc["small-text"]}</p>
<div data-mce-fragment="1">
  <h5 data-mce-fragment="1">
    <img
      style="display: block; margin-left: auto; margin-right: auto"
      src="https://cdn.shopify.com/s/files/1/1463/8774/files/20190422154637_8fa2f341-9dce-4235-93c4-55ed3d98629e_large.jpg?v=1560380078"
      alt=""
      data-mce-fragment="1"
    />
  </h5>
  <p data-mce-fragment="1">
    <span style="color: #f9d308" data-mce-fragment="1"
      ><span style="color: #c49e00"
        ><strong data-mce-fragment="1"
          >Dit product wordt niet in de winkel verkocht dus pak deze geweldige
          deal</strong
        ></span
      >,</span
    >&nbsp;zolang de actie duurt en de voorraad strekt.<br
      data-mce-fragment="1"
    /><br data-mce-fragment="1" />Bestel nu en profiteer vandaag nog van de<span
      style="color: #c49e00"
      data-mce-fragment="1"
      ><strong data-mce-fragment="1"
        >&nbsp;kortlopende&nbsp;50% KORTING! OP=OP</strong
      ></span
    >
  </p>
  <p data-mce-fragment="1">
    <strong
      ><span style="color: #050505" data-mce-fragment="1"
        >Specificaties:</span
      ></strong
    >
  </p>
  <div data-mce-fragment="1">
    <ul data-mce-fragment="1">
      ${desc["specifications"]}
    </ul>
  </div>
  <p data-mce-fragment="1">
    <strong
      ><span style="color: #000000" data-mce-fragment="1"
        >Pakket inbegrepen:</span
      ></strong
    >
  </p>
  <div data-mce-fragment="1">
    <ul data-mce-fragment="1">
      ${desc["package"]}
    </ul>
  </div>
</div>
<p style="text-align: center" data-mce-fragment="1">
  <strong data-mce-fragment="1"
    >100% tevredenheidsgarantie<br data-mce-fragment="1" /></strong
  >Meer dan 8500+ tevreden klanten gingen je al voor!
</p>
<p style="text-align: center" data-mce-fragment="1">
  <strong data-mce-fragment="1">😃</strong>&nbsp;<strong data-mce-fragment="1"
    >98,9% raadt onze producten aan</strong
  >, en dat wordt ondersteund door een risicovrije 14 dagen garantie.<br
    data-mce-fragment="1"
  /><br data-mce-fragment="1" />✔️&nbsp;Als er toch iets mis is met je
  bestelling, wordt er&nbsp;<strong data-mce-fragment="1"
    >ALLES aan&nbsp;gedaan&nbsp;om te zorgen dat jij 100% tevreden bent met jouw
    aankoop.</strong
  >
</p>
<p style="text-align: center" data-mce-fragment="1">
  Is het probleem niet op te lossen&nbsp;of ben jij niet tevreden met jouw aankoop?<strong data-mce-fragment="1"
    >Geen probleem! Dan geldt de&nbsp;niet-tevreden-geld-terug garantie.<br
      data-mce-fragment="1" /></strong
  ><br data-mce-fragment="1" />📩&nbsp;<strong data-mce-fragment="1"
    >Per mail 24/7 beschikbaar voor je vragen.</strong
  >&nbsp;Neem daarvoor contact op met de klantenservice.<strong
    data-mce-fragment="1"
    ><br data-mce-fragment="1"
  /></strong>
</p>
<p style="text-align: center" data-mce-fragment="1">
  <strong data-mce-fragment="1">🔐&nbsp;Gegarandeerd veilig betalen!</strong
  >&nbsp;Je kan gebruik maken van de meest gebruikte Payment Provider van
  Nederland &amp; Belgi&euml;, genaamd Mollie.
</p>
<div data-mce-fragment="1">
  <img
    style="display: block; margin-left: auto; margin-right: auto"
    src="https://cdn.shopify.com/s/files/1/0291/8943/9626/files/Afbeelding1_480x480.png?v=1589039509"
    alt=""
    width="406"
    height="101"
    data-mce-fragment="1"
  />
</div>
<p data-mce-fragment="1">&nbsp;</p>

`;
    this.setState({
      loaded: true,
      out: test,
    });
    this.copyToClip(test);
  };

  copyToClip(text) {
    var dummy = document.createElement("input");
    document.body.appendChild(dummy);
    dummy.setAttribute("value", text);
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }
  render() {
    const { template, loaded, out } = this.state;

    return (
      <React.Fragment>
        <form id="rendered-form" onSubmit={this.handleGenerate}>
          <div className="rendered-form">
            <div className="">
              <h1 access="false" id="control-9317360">
                Product Description Generator
              </h1>
              <h5> Please use "ENTER" to delimit for lists :)</h5>
            </div>
            <div className="formbuilder-text form-group field-intro">
              <label for="name" className="formbuilder-text-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                name="name"
                access="false"
                id="name"
                ref="name"
              />
            </div>
            <div className="formbuilder-text form-group field-intro">
              <label for="intro" className="formbuilder-text-label">
                Intro
              </label>
              <textarea
                type="textarea"
                className="form-control"
                name="intro"
                access="false"
                id="intro"
                ref="intro"
              />
            </div>

            <div className="formbuilder-textarea form-group field-main-text">
              <label for="main-text" className="formbuilder-textarea-label">
                Tekst
              </label>
              <textarea
                type="textarea"
                className="form-control"
                name="main-text"
                access="false"
                id="main-text"
              ></textarea>
            </div>

            <div className="formbuilder-textarea form-group field-voordelen">
              <label for="voordelen" className="formbuilder-textarea-label">
                Voordelen
              </label>
              <textarea
                type="textarea"
                className="form-control"
                name="voordelen"
                access="false"
                id="voordelen"
                title='Delimit with " - "'
              ></textarea>
            </div>

            <div className="formbuilder-text form-group field-small-text">
              <label for="small-text" className="formbuilder-text-label">
                Small text
              </label>
              <textarea
                type="text"
                className="form-control"
                name="small-text"
                access="false"
                id="small-text"
              />
            </div>

            <div className="formbuilder-textarea form-group field-specifications">
              <label
                for="specifications"
                className="formbuilder-textarea-label"
              >
                Specifications
              </label>
              <textarea
                type="textarea"
                className="form-control"
                name="specifications"
                access="false"
                id="specifications"
              ></textarea>
            </div>
            <div className="formbuilder-textarea form-group field-package">
              <label for="package" className="formbuilder-textarea-label">
                Package Includes
              </label>
              <textarea
                type="textarea"
                className="form-control"
                name="package"
                access="false"
                id="package"
              ></textarea>
            </div>
            <div className="formbuilder-button form-group field-button-1608929995987">
              <button
                type="submit"
                className="btn-success btn"
                name="button-1608929995987"
                value="Generate"
                access="false"
                id="button-1608929995987"
              >
                <div></div>
                {loaded ? "*copied*!" : "Copy to Clip"}
              </button>
            </div>
          </div>
        </form>

        <div id="output"></div>
      </React.Fragment>
    );
  }
}

export default Creator;
