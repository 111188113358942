import React from 'react'
import Creator  from  './Creator';
import Login from './Login.js';

class Main extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
          authenticated : false,
      }
  }

  loginCallback = () => {
      this.setState({
          authenticated: true
      })
  }
    render() {
        const {authenticated} = this.state;
        return (
            authenticated? <Creator/> : <Login cb={this.loginCallback}/>
        )

    }
}

export default Main
